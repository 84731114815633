import { MUI_THEME } from '@/modules/Theme/constant.js';
import { BUTTON_VARIANTS } from '@/utils/materialTheme/constant.js';

export const rootStyles = ({ theme, button }) => {
  const isLight = theme.palette.mode === MUI_THEME['light-mode'];

  if (button?.ownerState.variant === BUTTON_VARIANTS.V3_SECONDARY) {
    return {
      background: 'var(--light-grey-1-color)',
      color: 'var(--color-black)',
      borderRadius: '8px !important',
      textTransform: 'none',
      boxSizing: 'border-box',
      ':hover': {
        background: 'var(--light-gray-3)',
        color: 'var(--color-black)!important',
      },
      ':disabled': {
        background: 'var(--light-gray-3)',
        color: 'var(--grey-text-v3)',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.V3_PRIMARY) {
    return {
      background: 'var(--color-black)',
      color: 'var(--color-white)',
      borderRadius: '8px !important',
      textTransform: 'none',
      boxSizing: 'border-box',
      ':hover': {
        background: 'var(--full-dark-grey)',
        color: 'var(--color-white)',
      },
      ':disabled': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF',
        backdropFilter: 'blur(4px)',
        color: 'var(--color-white)',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.V3_TETRIARY) {
    return {
      background: 'transparent',
      borderRadius: '8px !important',
      border: '1px solid var(--light-gray)',
      textTransform: 'none',
      boxSizing: 'border-box',
      ':hover': {
        background: 'var(--light-gray-3)',
        color: 'var(--color-black)!important',
      },
      ':disabled': {
        background: 'var(--light-gray-3)',
        color: 'var(--grey-text-v3)',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.V3_OVERLAY) {
    return {
      background: ' rgba(0, 0, 0, 0.3)',
      borderRadius: '8px !important',
      backdropFilter: 'blur(4px)',
      textTransform: 'none',
      color: 'var(--color-white)',
      boxSizing: 'border-box',
      minWidth: 10,
      ':hover': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF',
        backdropFilter: 'blur(4px)',
        color: 'var(--color-white)',
      },
      ':disabled': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF',
        backdropFilter: 'blur(4px)',
        color: 'var(--color-white)',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.TERTIARY) {
    return {
      background: isLight ? 'transparent' : 'transparent',
      borderRadius: '50px !important',
      border: isLight
        ? '1px solid var(--bg-light-grey-3)'
        : '1px solid var(--bg-medium-grey-color)',
      color: isLight ? 'var(--text-primary)' : 'var(--bg-medium-grey-color)',
      textTransform: 'none',
      ':hover': {
        border: isLight
          ? '1px solid var(--border-tetriary)'
          : '1px solid white',
        color: isLight ? 'var(--text-primary)' : 'white',
      },
      ':disabled': {
        // color: '#86868B',
      },
      '&.Mui-disabled svg path': {
        // fill: '#86868B',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.SAVE) {
    return {
      background: isLight ? 'var(--light-grey-1-color)' : '#E0E0E0',
      borderRadius: '50px !important',
      color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      ':hover': {
        background: isLight
          ? 'var(--bg-light-grey-3)'
          : 'var(--surface-light-grey-3)',
        color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
      },
      ':disabled': {
        color: 'var(--text-secondary)',
        background: 'var(--bg-light-grey-2)',
        'svg path': {
          fill: '#86868B',
        },
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.WHITE) {
    return {
      background: isLight ? '#FFF' : '#E0E0E0',
      borderRadius: '50px !important',
      color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      ':hover': {
        background: isLight
          ? 'var(--surface-light-gray-1)'
          : 'var(--surface-light-grey-3)',
        color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
      },
      ':disabled': {
        color: 'var(--text-secondary)',
        background: '#FFF',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.FILTER) {
    return {
      border: '1px solid #E4E4E4',
      background: isLight ? 'var(--bg-white)' : '#E0E0E0',
      color: 'var(--text-bg-black)',
      textTransform: 'none',

      ':hover': {
        border: '1px solid var(--border-tetriary)',
        background: isLight ? 'var(--bg-white)' : 'var(--surface-light-grey-2)',
      },
      ':disabled': isLight
        ? {
            opacity: '.95',
            'svg path': {
              fill: '#86868B',
            },
          }
        : {
            background: 'var(--surface-light-grey-2)',
            color: '#86868B',
          },
    };
  }

  if (button?.ownerState.variant === BUTTON_VARIANTS.SUBMIT) {
    return {
      background: isLight ? 'var(--surface-dark)' : '#fff',
      borderRadius: '50px',
      color: 'var(--bg-white)',
      textTransform: 'none',

      ':disabled': {
        background: 'var(--surface-light-grey-2)',
        color: 'var(--text-secondary)',
      },
      ':active': {
        background: isLight
          ? 'var(--surface-black)'
          : 'var(--surface-light-grey-3)',
      },
      ':focus': {
        background: isLight
          ? 'var(--surface-black)'
          : 'var(--surface-light-grey-3)',
      },
      ':hover': {
        background: isLight
          ? 'var(--surface-black)'
          : 'var(--surface-light-grey-3)',
      },
    };
  }

  if (button?.ownerState.variant === BUTTON_VARIANTS.CANCEL) {
    return {
      background: 'transparent',
      color: 'var(--bg-medium-grey-color)',
      textTransform: 'none',
      borderRadius: '50px',
      ':disabled': {
        // background: 'var(--bg-light-grey-2)',
      },
      ':active': {
        background: 'var(--light-grey-1-color)',
        color: 'var(--text-primary);',
      },
      ':focus': {
        background: 'var(--light-grey-1-color)',
        color: 'var(--text-primary);',
      },
      ':hover': {
        background: 'var(--light-grey-1-color)',
        color: 'var(--text-primary);',
      },
    };
  }

  if (button?.ownerState.variant === BUTTON_VARIANTS.TRANSPARENT) {
    return {
      background: 'rgba(0, 0, 0, 0.4)',
      borderRadius: '32px',
      lineHeight: '2rem',
      padding: '.6rem 1.2rem',
      fontSize: 'var(--font-size-lg)',
      color: 'var(--tetriary-color)',
      textTransform: 'none',
    };
  }

  if (button?.ownerState.variant === BUTTON_VARIANTS.DARK) {
    return {
      background: '#000',
      borderRadius: '8px',
      lineHeight: '2rem',
      fontSize: 'var(--font-size-sm-1) !important',
      fontWeight: 400,
      color: '#F2F2F2',
      textTransform: 'none',
      ':hover': {
        background: '#000',
        color: '#F2F2F2',
      },
      ':disabled': {
        color: 'var(--text-secondary)',
      },
    };
  }

  if (button?.ownerState.variant === BUTTON_VARIANTS.OUTLINED_GREY) {
    return {
      borderRadius: '8px',
      lineHeight: '2rem',
      fontSize: 'var(--font-size-sm-1) !important',
      fontWeight: 400,
      color: '#86868B',
      border: 'thin solid #F5F5F7',
      textTransform: 'none',
      ':hover': {
        color: 'var(--text-secondary)',
      },
      ':disabled': {
        color: 'var(--text-secondary)',
      },
    };
  }

  if (button?.ownerState.variant === BUTTON_VARIANTS.TEXT) {
    return {
      background: 'transparent',
      borderRadius: '50px',
      color: 'var(--text-primary)',
      textTransform: 'none',
      ':disabled': {
        color: 'var(--text-secondary)',
      },
      ':hover': {
        background: 'var(--bg-light-grey-3)',
        color: 'var(--text-primary)',
      },
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.V3_GHOST) {
    return {
      background: isLight ? 'transparent' : 'transparent',
      borderRadius: '8px !important',
      border: isLight ? 'none' : 'none',
      color: isLight ? 'var(--color-black)' : 'var(--color-black)',
      textTransform: 'none',
      ':hover': {
        background: 'var(--light-gray-3)',
        color: 'var(--color-black)!important',
      },
      ':disabled': {
        background: 'var(--light-gray-3)',
        color: 'var(--grey-text-v3)',
      },
      '&.Mui-disabled svg path': {},
    };
  }
  if (button?.ownerState.variant === BUTTON_VARIANTS.V3_ACCENT) {
    return {
      background: 'var(--color-tag-yellow)',
      color: 'var(--color-black)',
      textTransform: 'none',
      borderRadius: '8px !important',
      ':disabled': {
        background: 'var(--light-gray)',
        color: 'var(--light-grey-1-color)',
      },
      ':hover': { background: 'var(--color-tag-yellow-hover)' },
    };
  }

  return {};
};
