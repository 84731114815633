import { PERMISSION_ENUM } from '@/utils/permissions/index.js';

export const ACCESS = {
  UPDATE_MATERIAL: [PERMISSION_ENUM.UPDATE_MATERIAL],
  GET_RENDER_TYPES: [PERMISSION_ENUM.GET_RENDER_TYPES],
  GET_PRESET_CLASSES: [PERMISSION_ENUM.GET_PRESET_CLASSES],
  GET_MATERIAL: [PERMISSION_ENUM.GET_MATERIAL],
  GET_PRESETS: [PERMISSION_ENUM.GET_MATERIAL_PRESETS],
  GET_SCENES: [PERMISSION_ENUM.GET_SCENES],
  GET_SURFACES: [PERMISSION_ENUM.GET_SURFACES],
  GET_LAYOUTS: [PERMISSION_ENUM.GET_LAYOUTS],
  GET_MANUFACTURERS: [PERMISSION_ENUM.GET_MANUFACTURERS],
  GET_MANUFACTURER: [PERMISSION_ENUM.GET_MANUFACTURER],
  ADD_USER: [PERMISSION_ENUM.ADD_USER],
  DOWNLOAD_PRESET_FILE: [PERMISSION_ENUM.DOWNLOAD_PRESET_FILE],
  DOWNLOAD_MATERIAL_IMAGES: [PERMISSION_ENUM.DOWNLOAD_MATERIAL_IMAGES],
  DOWNLOAD_MATERIAL_SECTION: [
    PERMISSION_ENUM.DOWNLOAD_MATERIAL_IMAGES,
    PERMISSION_ENUM.DOWNLOAD_PRESET_FILE,
  ],
  GET_PRESET: [PERMISSION_ENUM.GET_PRESET],
  CHANGE_DIGITAL_STATUS: [PERMISSION_ENUM.CHANGE_DIGITAL_STATUS],
  ADD_RENDER_TYPE: [PERMISSION_ENUM.ADD_RENDER_TYPE],
  ADD_PRESET_CLASS: [PERMISSION_ENUM.ADD_PRESET_CLASS],
  UPLOAD_RENDER_TYPE_MAP: [PERMISSION_ENUM.UPLOAD_RENDER_TYPE_MAP],
  ADD_ROLE: [PERMISSION_ENUM.ADD_ROLE],
  GET_ROLE: [PERMISSION_ENUM.GET_ROLE],
  GET_USER: [PERMISSION_ENUM.GET_USER],
  GET_COLLECTION: [PERMISSION_ENUM.GET_COLLECTION],
  GET_RENDER_TYPE: [PERMISSION_ENUM.GET_RENDER_TYPE],
  GET_PRESET_CLASS: [PERMISSION_ENUM.GET_PRESET_CLASS],
  GET_SCENE: [PERMISSION_ENUM.GET_SCENE],
  UPLOAD_SCENE_BACKGROUND: [PERMISSION_ENUM.UPLOAD_SCENE_BACKGROUND],
  UPLOAD_SCENE: [PERMISSION_ENUM.UPLOAD_SCENE],
  UPLOAD_SVG_MASK: [PERMISSION_ENUM.UPLOAD_SVG_MASK],
  ADD_SURFACE: [PERMISSION_ENUM.ADD_SURFACE],
  GET_SPACE_APPS: [PERMISSION_ENUM.GET_SPACE_APPS],
  GET_SURFACE: [PERMISSION_ENUM.GET_SURFACE],
  GET_TASK: [PERMISSION_ENUM.GET_TASK],
  DOWNLOAD_MATERIAL_IMAGES_BY_TASK_ID: [
    PERMISSION_ENUM.DOWNLOAD_MATERIAL_IMAGES_BY_TASK_ID,
  ],
  GET_MATERIALS: [PERMISSION_ENUM.GET_MATERIALS],
  ADD_PRESET: [PERMISSION_ENUM.ADD_PRESET],
  GET_USERS: [PERMISSION_ENUM.GET_USERS],
  ADD_SCENE: [PERMISSION_ENUM.ADD_SCENE],
  UPDATE_MATERIALS_ASSIGNEE_DISTRIBUTE: [
    PERMISSION_ENUM.UPDATE_MATERIALS_ASSIGNEE_DISTRIBUTE,
  ],
  GET_COLLECTIONS: [PERMISSION_ENUM.GET_COLLECTIONS],
  START_RENDERING: [PERMISSION_ENUM.START_RENDERING],
  ADD_INSTALL_SHOT: [PERMISSION_ENUM.ADD_INSTALL_SHOT],
  UPDATE_INSTALL_SHOT_SURFACES: [PERMISSION_ENUM.UPDATE_INSTALL_SHOT_SURFACES],
};

export const ACCESS_KEYS_ENUM = {
  UPDATE_MATERIAL: 'UPDATE_MATERIAL',
  GET_RENDER_TYPES: 'GET_RENDER_TYPES',
  GET_PRESET_CLASSES: 'GET_PRESET_CLASSES',
  GET_MATERIAL: 'GET_MATERIAL',
  GET_PRESETS: 'GET_PRESETS',
  GET_SCENES: 'GET_SCENES',
  GET_SURFACES: 'GET_SURFACES',
  GET_LAYOUTS: 'GET_LAYOUTS',
  GET_COLLECTIONS: 'GET_COLLECTIONS',
  GET_MANUFACTURERS: 'GET_MANUFACTURERS',
  GET_MANUFACTURER: 'GET_MANUFACTURER',
  ADD_USER: 'ADD_USER',
  DOWNLOAD_PRESETS: 'DOWNLOAD_PRESETS',
  DOWNLOAD_MATERIAL_IMAGES: 'DOWNLOAD_MATERIAL_IMAGES',
  DOWNLOAD_MATERIAL_SECTION: 'DOWNLOAD_MATERIAL_SECTION',
  DOWNLOAD_PRESET_FILE: 'DOWNLOAD_PRESET_FILE',
  GET_PRESET: 'GET_PRESET',
  CHANGE_DIGITAL_STATUS: 'CHANGE_DIGITAL_STATUS',
  ADD_PRESET_CLASS: 'ADD_PRESET_CLASS',
  ADD_RENDER_TYPE: 'ADD_RENDER_TYPE',
  UPLOAD_RENDER_TYPE_MAP: 'UPLOAD_RENDER_TYPE_MAP',
  ADD_ROLE: 'ADD_ROLE',
  GET_ROLE: 'GET_ROLE',
  GET_USER: 'GET_USER',
  GET_COLLECTION: 'GET_COLLECTION',
  GET_RENDER_TYPE: 'GET_RENDER_TYPE',
  GET_PRESET_CLASS: 'GET_PRESET_CLASS',
  GET_SCENE: 'GET_SCENE',
  UPLOAD_SCENE: 'UPLOAD_SCENE',
  UPLOAD_SCENE_BACKGROUND: 'UPLOAD_SCENE_BACKGROUND',
  UPLOAD_SVG_MASK: 'UPLOAD_SVG_MASK',
  ADD_SURFACE: 'ADD_SURFACE',
  GET_SPACE_APPS: 'GET_SPACE_APPS',
  GET_SURFACE: 'GET_SURFACE',
  GET_TASK: 'GET_TASK',
  DOWNLOAD_MATERIAL_IMAGES_BY_TASK_ID: 'DOWNLOAD_MATERIAL_IMAGES_BY_TASK_ID',
  GET_MATERIALS: 'GET_MATERIALS',
  ADD_PRESET: 'ADD_PRESET',
  UPDATE_MATERIALS_ASSIGNEE_DISTRIBUTE: 'UPDATE_MATERIALS_ASSIGNEE_DISTRIBUTE',
  GET_USERS: 'GET_USERS',
  ADD_SCENE: 'ADD_SCENE',
  START_RENDERING: 'START_RENDERING',
  ADD_INSTALL_SHOT: 'ADD_INSTALL_SHOT',
  UPDATE_INSTALL_SHOT_SURFACES: 'UPDATE_INSTALL_SHOT_SURFACES',
};

export const ACCESS_URL = {
  GET_ROLE: 'role',
  GET_USER: 'user',
  GET_COLLECTION: 'collection',
  GET_RENDER_TYPE: 'render_type',
  GET_PRESET_CLASS: 'preset_class',
  GET_TASK: 'task',
  GET_SURFACE: 'surface',
  GET_SCENE: 'scene',
  GET_MATERIAL: 'material',
  GET_PRESET: 'preset',
};
