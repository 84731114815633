import { MaterialsProvider } from '@/contexts/materialsContext.js';
import { SlideProvider } from '@/contexts/slideContext.js';
import { hydrateAuth } from '@/modules/Auth/stores/auth-store.js';
import { AppThemeProvider } from '@/modules/Theme/context.js';
import Routes from '@/routes/index.js';
import ErrorBoundary from '@/shared/components/ErrorBoundary/index.js';

import '@/shared/styles/variables.scss';
import './App.css';
hydrateAuth();

function App() {
  return (
    <ErrorBoundary>
      <AppThemeProvider>
        <MaterialsProvider>
          <SlideProvider>
            <Routes />
          </SlideProvider>
        </MaterialsProvider>
      </AppThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
