export const MATERIAL_SORT_MENU = [
  {
    label: 'Collection',
    value: 'collections.title',
  },
  {
    label: 'Brand',
    value: 'manufacturers.title',
  },
  {
    label: 'Status',
    value: 'digitalizing_status',
  },
  {
    label: 'Last change',
    value: 'modified',
  },
];

export const MODAL_TYPES = {
  CONFIRM: 'confirm',
  EDIT_RENDER: 'edit_render',
  START_RENDER: 'start_render',
  LINK_PRESET: 'link_preset',
  ASSIGN_PRESET_CLASS: 'assign_preset_class',
  ETL_UPDATE: 'etl_update',
  DISTRIBUTE_MATERIALS: 'distribute_materials',
  CREATE_INSTALL_SHOT: 'install_shot_create',
};
