export const MuiMenu = {
  styleOverrides: {
    root: () => {
      return {
        '& .MuiList-root': {
          height: '100%',
          boxSizing: 'border-box',
          overflow: 'auto',
        },
      };
    },
  },
};
