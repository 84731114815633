import { MODAL_TYPES } from '@/shared/components/design-v3/MaterialFilters/constant.js';
import { BATCH_EDIT_ACTIONS } from '@/utils/filters.js';
import { PERMISSION_KEYS_ENUM } from '@/utils/permissions/index.js';

export const HEADER_TAB = {
  MATERIALS: 0,
  ADMIN: 1,
};
export const HEADER_TABS = [
  {
    label: 'Materials',
    value: HEADER_TAB.MATERIALS,
    permissionKeys: [PERMISSION_KEYS_ENUM.MATERIALS],
  },
  {
    label: 'Admin',
    value: HEADER_TAB.ADMIN,
    permissionKeys: [
      PERMISSION_KEYS_ENUM.USERS,
      PERMISSION_KEYS_ENUM.MANUFACTURERS,
      PERMISSION_KEYS_ENUM.PRESETS,
      PERMISSION_KEYS_ENUM.PRESET_CLASSES,
      PERMISSION_KEYS_ENUM.MATERIAL_TASKS,
      PERMISSION_KEYS_ENUM.ROLES,
      PERMISSION_KEYS_ENUM.SURFACES,
      PERMISSION_KEYS_ENUM.RENDER_TYPES,
      PERMISSION_KEYS_ENUM.COLLECTIONS,
      PERMISSION_KEYS_ENUM.SCENES,
    ],
  },
];

export const modalTypeMap = {
  [BATCH_EDIT_ACTIONS.RENDER_EDIT]: MODAL_TYPES.EDIT_RENDER,
  [BATCH_EDIT_ACTIONS.START_RENDER_QA]: MODAL_TYPES.START_RENDER,
  [BATCH_EDIT_ACTIONS.START_RENDER_PROD]: MODAL_TYPES.START_RENDER,
  [BATCH_EDIT_ACTIONS.ASSIGN_PRESET_CLASS]: MODAL_TYPES.ASSIGN_PRESET_CLASS,
  [BATCH_EDIT_ACTIONS.LINK_PRESETS]: MODAL_TYPES.LINK_PRESET,
  [BATCH_EDIT_ACTIONS.ETL_UPDATE]: MODAL_TYPES.ETL_UPDATE,
  [BATCH_EDIT_ACTIONS.DISTRIBUTE_MATERIALS]: MODAL_TYPES.DISTRIBUTE_MATERIALS,
  [BATCH_EDIT_ACTIONS.INSTALL_SHOT_CREATION]: MODAL_TYPES.CREATE_INSTALL_SHOT,
};
