export const PERMISSION_KEYS_ENUM = {
  RENDER: 'Render',
  AUTH: 'Auth',
  USERS: 'Users',
  ROLES: 'Roles',
  PERMISSIONS: 'Permissions',
  MANUFACTURERS: 'Manufacturers',
  COLLECTIONS: 'Collections',
  MATERIAL_PROCESSING: 'Material processing',
  MATERIALS: 'Materials',
  PRESET_CLASSES: 'Preset classes',
  SURFACES: 'Surfaces',
  SCENES: 'Scenes',
  PRESETS: 'Presets',
  LAYOUTS: 'Layouts',
  RENDER_TYPES: 'Render types',
  MATERIAL_IMAGES: 'Material images',
  MATERIAL_TASKS: 'Material tasks',
  INSTALL_SHOTS: 'Install shots',
};

export const PERMISSION_ENUM = Object.freeze({
  ADD_COLLECTION: 'add_collection_collections_post',
  ADD_INSTALL_SHOT: 'add_install_shot_install_shots_post',
  ADD_LAYOUT: 'add_layout_layouts_post',
  ADD_MANUFACTURER: 'add_manufacturer_manufacturers_post',
  ADD_MATERIAL: 'add_material_materials_post',
  ADD_MATERIAL_IMAGE: 'add_material_image_material_images_post',
  ADD_PRESET: 'add_preset_material_presets_post',
  ADD_PRESET_CLASS: 'add_preset_class_preset_classes_post',
  ADD_RENDER_TYPE: 'add_render_type_render_types_post',
  ADD_ROLE: 'add_role_roles_post',
  ADD_SCENE: 'add_scene_scenes_post',
  ADD_SURFACE: 'add_surface_surfaces_post',
  ADD_TASK: 'add_task_tasks_post',
  ADD_USER: 'add_user_users_post',
  CHANGE_DIGITAL_STATUS:
    'change_digital_status_materials_change_digital_status_patch',
  CHANGE_USER_PASSWORD:
    'change_user_password_users_change_password__user_id__patch',
  DELETE_COLLECTION: 'delete_collection_collections__collection_id__delete',
  DELETE_INSTALL_SHOT:
    'delete_install_shot_install_shots__install_shot_id__delete',
  DELETE_INSTALL_SHOT_BY_SPACE_APPLICATION:
    'delete_install_shot_by_space_application_install_shots_by_space_application_delete',
  DELETE_LAYOUT: 'delete_layout_layouts__layout_id__delete',
  DELETE_MANUFACTURER:
    'delete_manufacturer_manufacturers__manufacturer_id__delete',
  DELETE_MATERIAL: 'delete_material_materials__material_id__delete',
  DELETE_MATERIAL_IMAGE:
    'delete_material_image_material_images__material_image_id__delete',
  DELETE_PRESET: 'delete_preset_material_presets__preset_id__delete',
  DELETE_RENDER_TYPE: 'delete_render_type_render_types__type_id__delete',
  DELETE_ROLE: 'delete_role_roles__role_id__delete',
  DELETE_SCENE: 'delete_scene_scenes__scene_id__delete',
  DELETE_SURFACE: 'delete_surface_surfaces__surface_id__delete',
  DELETE_TASK: 'delete_task_tasks__task_id__delete',
  DELETE_USER: 'delete_user_users__user_id__delete',
  DOWNLOAD_MATERIAL_IMAGES:
    'download_material_images_material_images_download__material_id__get',
  DOWNLOAD_MATERIAL_IMAGES_BY_TASK_ID:
    'download_material_images_by_task_id_material_images_download_by_task__task_id__get',
  DOWNLOAD_PRESET_FILE:
    'download_preset_file_material_presets_download__preset_id__get',
  GET_COLLECTION: 'get_collection_collections__collection_id__get',
  GET_COLLECTION_BY_INTERNAL_ID:
    'get_collection_by_internal_id_collections_by_internal_id__internal_id__get',
  GET_COLLECTIONS: 'get_collections_collections_get',
  GET_INSTALL_SHOT: 'get_install_shot_install_shots__install_shot_id__get',
  GET_INSTALL_SHOTS: 'get_install_shots_install_shots_get',
  GET_LAYOUT: 'get_layout_layouts__layout_id__get',
  GET_LAYOUTS: 'get_layouts_layouts_get',
  GET_MANUFACTURER: 'get_manufacturer_manufacturers__manufacturer_id__get',
  GET_MANUFACTURER_BY_INTERNAL_ID:
    'get_manufacturer_by_internal_id_manufacturers_by_internal_id__internal_id__get',
  GET_MANUFACTURERS: 'get_manufacturers_manufacturers_get',
  GET_MATERIAL: 'get_material_materials__material_id__get',
  GET_MATERIAL_BY_INTERNAL_SKU:
    'get_material_by_internal_sku_materials_by_internal_sku__internal_sku__get',
  GET_MATERIAL_IMAGE:
    'get_material_image_material_images__material_image_id__get',
  GET_MATERIAL_IMAGES: 'get_material_images_material_images_get',
  GET_MATERIAL_PRESETS: 'get_material_presets_material_presets_get',
  GET_MATERIALS: 'get_materials_materials_get',
  GET_MATERIALS_BY_INSTALL_SHOTS:
    'get_materials_by_install_shots_materials_by_install_shots_get',
  GET_MATERIALS_BY_RENDER_TASKS:
    'get_materials_by_render_tasks_materials_by_render_tasks_get',
  GET_PERMISSIONS: 'get_permissions_permissions_get',
  GET_PRESET: 'get_preset_material_presets__preset_id__get',
  GET_PRESET_CLASS: 'get_preset_class_preset_classes__preset_class_id__get',
  GET_PRESET_CLASSES: 'get_preset_classes_preset_classes_get',
  GET_RENDER_TYPE: 'get_render_type_render_types__type_id__get',
  GET_RENDER_TYPES: 'get_render_types_render_types_get',
  GET_ROLE: 'get_role_roles__role_id__get',
  GET_ROLES: 'get_roles_roles_get',
  GET_SCENE: 'get_scene_scenes__scene_id__get',
  GET_SCENES: 'get_scenes_scenes_get',
  GET_SPACE_APPS: 'get_space_apps_materials_space_applications_get',
  GET_SURFACE: 'get_surface_surfaces__surface_id__get',
  GET_SURFACES: 'get_surfaces_surfaces_get',
  GET_TASK: 'get_task_tasks__task_id__get',
  GET_TASKS: 'get_tasks_tasks_get',
  GET_USER: 'get_user_users__user_id__get',
  GET_USER_ME: 'get_user_me_users_me_get',
  GET_USERS: 'get_users_users_get',
  LIST_CREATED_SPACE_APPLICATIONS:
    'list_created_space_applications_install_shots_space_applications_get',
  LOGIN_USER: 'login_user_auth_login_post',
  LOGOUT_USER: 'logout_user_auth_logout_post',
  PATCH_SURFACE_TO_SCENE_FLAGS:
    'patch_surface_to_scene_flags_scenes_surface_to_scene_flags__scene_id___surface_id__patch',
  RESET_MATERIALS_LAYOUTS:
    'reset_materials_layouts_materials_reset_layouts__material_id__patch',
  START_ETL: 'start_etl_materials_start_etl_get',
  START_INSTALL_SHOTS: 'start_install_shots_materials_start_install_shots_get',
  START_INSTALL_SHOTS_INSTALL_SHOTS_START_INSTALL_SHOTS_GET:
    'start_install_shots_install_shots_start_install_shots_get',
  START_INSTALL_SHOTS_INSTALL_SHOTS_START_GET:
    'start_install_shots_install_shots_start_get',
  START_PROCESSING: 'start_processing_processing_start_get',
  START_RENDERING: 'start_rendering_render_start_get',
  START_SYNC_ETL: 'start_sync_etl_materials_start_sync_etl_get',
  START_UPDATE_MATERIAL_ETL:
    'start_update_material_etl_materials_start_update_material_etl_get',
  UPDATE_COLLECTION: 'update_collection_collections__collection_id__put',
  UPDATE_INSTALL_SHOT:
    'update_install_shot_install_shots__install_shot_id__put',
  UPDATE_INSTALL_SHOT_RANKS:
    'update_install_shot_ranks_install_shots_change_ranks_patch',
  UPDATE_INSTALL_SHOT_STATUS:
    'update_install_shot_status_install_shots_active__install_shot_id__patch',
  UPDATE_INSTALL_SHOT_SURFACES:
    'update_install_shot_surfaces_install_shots_surfaces__install_shot_id__patch',
  UPDATE_LAYOUT: 'update_layout_layouts__layout_id__put',
  UPDATE_LAYOUT_RANK: 'update_layout_rank_layouts_change_ranks_patch',
  UPDATE_MANUFACTURER:
    'update_manufacturer_manufacturers__manufacturer_id__put',
  UPDATE_MATERIAL: 'update_material_materials__material_id__put',
  UPDATE_MATERIAL_CONFIG:
    'update_material_config_materials_render_config__material_id__patch',
  UPDATE_MATERIAL_IMAGE:
    'update_material_image_material_images__material_image_id__put',
  UPDATE_MATERIAL_PROPERTIES:
    'update_material_properties_materials_render_properties__material_id__patch',
  UPDATE_MATERIAL_SPACE_APPS:
    'update_material_space_apps_materials_space_applications__material_id__patch',
  UPDATE_MATERIALS_ASSIGNEE:
    'update_materials_assignee_materials_add_assignee_patch',
  UPDATE_MATERIALS_ASSIGNEE_DISTRIBUTE:
    'update_materials_assignee_materials_distribute_assignees_patch',
  UPDATE_MATERIALS_LAYOUTS:
    'update_materials_layouts_materials_change_layouts_patch',
  UPDATE_MATERIALS_PRESET: 'update_materials_preset_materials_add_preset_patch',
  UPDATE_MATERIALS_PRESET_CLASS:
    'update_materials_preset_class_materials_add_preset_class_patch',
  UPDATE_MATERIALS_RENDER_TYPE:
    'update_materials_render_type_materials_change_render_type_patch',
  UPDATE_MATERIALS_SEAMLESS:
    'update_materials_seamless_materials_change_seamless__material_id__patch',
  UPDATE_PRESET: 'update_preset_material_presets__preset_id__put',
  UPDATE_PRESET_CLASS:
    'update_preset_class_preset_classes__preset_class_id__put',
  UPDATE_PUBLISH_TASKS: 'update_publish_tasks_tasks_patch',
  UPDATE_PUBLISH_TASKS_TASKS_PUBLISHED_PATCH:
    'update_publish_tasks_tasks_published_patch',
  UPDATE_RENDER_TYPE: 'update_render_type_render_types__type_id__put',
  UPDATE_ROLE: 'update_role_roles__role_id__put',
  UPDATE_SCENE: 'update_scene_scenes__scene_id__put',
  UPDATE_SURFACE: 'update_surface_surfaces__surface_id__put',
  UPDATE_TASK_BY_WORKERS: 'update_task_by_workers_tasks_by_workers_patch',
  UPDATE_TASK_BY_WORKERS_TASKS_STATUS_PATCH:
    'update_task_by_workers_tasks_status_patch',
  UPDATE_USER: 'update_user_users__user_id__put',
  UPLOAD_RENDER_TYPE_MAP:
    'upload_render_type_map_render_types_upload_map__type_id___map_type__post',
  UPLOAD_SCENE: 'upload_scene_scenes_upload__scene_id__post',
  UPLOAD_SCENE_BACKGROUND:
    'upload_scene_background_scenes_upload_background__scene_id___lighting_type__post',
  UPLOAD_SVG_MASK:
    'upload_svg_mask_scenes_upload_svg_mask__scene_id___surface_id__post',
  USER_CHANGE_PASSWORD: 'user_change_password_auth_change_password_patch',
});
