export const PAPER_VARIANTS = {
  V3_DARK: 'v3_dark',
  V3_LIGHT: 'v3_light',
};
export const MuiPaper = {
  styleOverrides: {
    root: ({ ...paper }) => {
      if (paper?.ownerState.variant === PAPER_VARIANTS.V3_DARK) {
        return {
          maxHeight: '30vh !important',
          height: '40vh !important',
          backgroundColor: 'var(--dark-grey)',
          borderRadius: '8px',
          paddingBottom: '1rem',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        };
      }

      if (paper?.ownerState.variant === PAPER_VARIANTS.V3_LIGHT) {
        return {
          maxHeight: '30vh !important',
          height: '40vh !important',
          width: 'auto',
          backgroundColor: 'var(--light-grey-1-color)',
          borderRadius: '8px',
          overflow: 'hidden',
        };
      }

      return {};
    },
  },
};
