import httpService from '@/api/http.service.js';
import { getQueryParameters } from '@/utils/api.js';
const ROUTE = 'install-shots';

export const getInstallShots = (params) => httpService.get(ROUTE, { params });
export const startInstallShots = (params) =>
  httpService.get(`${ROUTE}/start`, {
    params: getQueryParameters(params),
    timeout: undefined,
  });
export const createInstallShots = (body) => httpService.post(ROUTE, body);
export const updateInstallShotSurfaces = (id, params) =>
  httpService.patch(`${ROUTE}/surfaces/${id}`, null, {
    params: getQueryParameters(params),
  });
export const getInstallShotSpaceApps = (params) =>
  httpService.get(`${ROUTE}/space-applications`, { params });
export const updateInstallShot = (id, body) =>
  httpService.put(`${ROUTE}/${id}`, body);
export const updateInstallShotActiveState = (id, params) =>
  httpService.patch(`${ROUTE}/active/${id}`, null, {
    params: getQueryParameters(params),
  });
export const updateInstallShotRank = (body) =>
  httpService.patch(`${ROUTE}/change-ranks`, body);
export const deleteInstallShotBySpaceApp = (params) =>
  httpService.delete(`${ROUTE}/by-space-application`, {
    params: getQueryParameters(params),
  });
