import { MUI_THEME } from '@/modules/Theme/constant.js';
import {
  AUTOCOMPLETE_SIZE,
  AUTOCOMPLETE_VARIANTS,
} from '@/utils/materialTheme/constant.js';

export const MuiAutocomplete = {
  defaultProps: {
    ListboxProps: {
      sx: {
        '&': {
          padding: '.8rem',
        },
        '& .MuiAutocomplete-option.Mui-focused': {
          background: 'var(--light-grey-1-color) !important',
          borderRadius: '4px',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          background: 'var(--light-grey-1-color) !important',
          borderRadius: '4px',
        },
        '& .MuiAutocomplete-option': {
          paddingLeft: '8px !important',
        },
      },
    },
  },
  styleOverrides: {
    root: ({ theme, ...props }) => {
      const variant = props.ownerState.variant;
      const isLight = theme.palette.mode === MUI_THEME['light-mode'];
      const autocompleteSize = props.ownerState.autocompleteSize;
      const commonStyles = {
        width: '100%',
        maxHeight: '60vh',
        overflowY: 'auto',
        paddingRight: '4px' /* scroll */,
        boxSizing: 'border-box',
      };

      const inputBaseRootStyles = {
        '&::before': {
          borderBottom: 'thin solid #4D4E58',
        },
        '&::after': {
          borderBottom: 'thin solid #4D4E58',
        },
        ...(!isLight && { color: 'var(--bg-white)' }),
      };

      const inputInputStyles = {
        ...(!isLight && { color: 'white !important' }),
      };

      const outlinedInputInputStyles = {
        ...(!isLight && { color: 'white' }),
      };

      const outlinedInputRootStyles = {
        common: {},
        [AUTOCOMPLETE_VARIANTS.GREY]: {
          borderRadius: '50px',
          padding: '1.2rem 7rem 1.2rem 1.6rem!important',
          background: isLight ? '#F5F5F7' : 'var(--light-grey-1-color)',
        },
        [AUTOCOMPLETE_VARIANTS.WHITE]: {
          borderRadius: '50px',
          padding: '1.2rem 1.6rem !important',
          background: 'var(--bg-white)',
        },
        [AUTOCOMPLETE_VARIANTS.V2_WHITE]: {
          borderRadius: '8px',
          background: 'var(--bg-white)',
        },
        [AUTOCOMPLETE_SIZE.LG]: {
          padding: '1.2rem 7rem 1.2rem 1.6rem!important',
        },
        [AUTOCOMPLETE_SIZE.MD]: {
          padding: '1rem 7rem 1rem 1.6rem!important',
        },
        [AUTOCOMPLETE_SIZE.xmd]: {
          padding: '.4rem .8rem !important',
        },
        [AUTOCOMPLETE_SIZE.sm]: {
          padding: '.6rem 1rem .6rem 1rem !important',
        },
      };

      const inputBaseInputStyles = {
        common: {},
        [AUTOCOMPLETE_VARIANTS.GREY]: {
          padding: '0 !important',
          lineHeight: '2.4rem',
        },
        [AUTOCOMPLETE_VARIANTS.WHITE]: {
          padding: '0 !important',
          lineHeight: '2.4rem',
        },
        [AUTOCOMPLETE_SIZE.LG]: {
          height: '2.4rem',
        },
        [AUTOCOMPLETE_SIZE.MD]: {
          height: '2rem',
        },
        [AUTOCOMPLETE_SIZE.xmd]: {
          padding: '.3rem !important',
          lineHeight: '1.6rem',
          fontSize: '13px',
          fontWeight: '400',
        },
        [AUTOCOMPLETE_SIZE.sm]: {
          lineHeight: '2.4rem',
          fontSize: '16px',
          fontWeight: '400',
          padding: '0 !important',
        },
      };

      const notchedOutlineStyles = {
        [AUTOCOMPLETE_VARIANTS.GREY]: {
          border: 'none',
        },
        [AUTOCOMPLETE_VARIANTS.WHITE]: {
          border: 'none',
        },
        [AUTOCOMPLETE_VARIANTS.V2_WHITE]: {
          border: 'none',
        },
      };

      const tagStyles = {
        ...(!isLight && {
          fontSize: 'var(--font-size-lg)',
          fontWeight: 400,
        }),
      };

      return {
        ...commonStyles,
        '& .MuiInputBase-root': inputBaseRootStyles,
        '& .MuiInput-input': inputInputStyles,
        '& .MuiOutlinedInput-input': outlinedInputInputStyles,
        '& .MuiOutlinedInput-root': {
          ...outlinedInputRootStyles.common,
          ...outlinedInputRootStyles[variant],
          ...outlinedInputRootStyles[autocompleteSize],
        },
        '& input.MuiInputBase-input': {
          ...inputBaseInputStyles.common,
          ...inputBaseInputStyles[variant],
          ...inputBaseInputStyles[autocompleteSize],
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
          notchedOutlineStyles[variant],
        ...tagStyles,
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: 'thin solid #d32f2f',
        },
      };
    },
  },
};
