import { TAB_MODE } from '@/utils/constant.js';

export const MuiTab = {
  styleOverrides: {
    root: ({ ownerState: { mode = TAB_MODE.BOTTOM } }) => {
      if (mode === TAB_MODE.V2) {
        return {
          color: 'var(--bg-medium-grey-color)',
          background: 'transparent',
          borderRadius: '8px',
          textTransform: 'capitalize',
          minHeight: '3.2rem',
          padding: '.4rem .6rem',
          fontWeight: 700,
          lineHeight: '24px',
          fontSize: 'var(--font-size-lg)',
          boxSizing: 'border-box',

          '&.Mui-selected': {
            boxSizing: 'border-box',
            background: 'var(--light-grey-1-color)',
            borderRadius: '8px',
            lineHeight: '24px',
            color: 'var(--color-black)',
            border: '1px solid var(--bg-medium-grey-color)',
          },
        };
      }

      if (mode === TAB_MODE.V3) {
        return {
          fontSize: '16px !important',
          fontWeight: 700,
          lineHeight: '2.2rem',
          color: '#86868B !important',
          background: 'transparent',
          textTransform: 'capitalize',
          padding: '1.3rem 0rem',

          '&.Mui-selected': {
            color: '#FFFFFF !important',
            borderBottom: '1px solid #FFFFFF',
          },
        };
      }

      if (mode === TAB_MODE.V3_PAGE) {
        return {
          fontSize: '16px !important',
          fontWeight: 700,
          lineHeight: '2.2rem',
          color: 'var(--color-silver-light) !important',
          background: 'white',
          textTransform: 'capitalize',
          padding: '.6rem 1.6rem',
          borderRadius: '8px',

          '&.Mui-selected': {
            borderBottom: 'none',
            background: 'var(--light-grey-1-color)',
            color: 'var(--color-black) !important',
            borderRadius: '8px',
          },
        };
      }

      if (mode === TAB_MODE.V3_DETAILS) {
        return {
          fontWeight: 700,
          fontSize: 'var(--font-size-lg)',
          color: 'var(--grey-text-v3) !important',
          background: 'white',
          textTransform: 'capitalize',
          padding: '12px 0',
          lineHeight: '2.2rem',
          minHeight: 0,
          minWidth: '10px',

          '&.Mui-selected': {
            borderRadius: '0',
            textAlign: 'left',
            fontWeight: 700,
            fontSize: 'var(--font-size-lg)',
            lineHeight: '2.2rem',
            color: 'var(--color-black) !important',
            minHeight: 0,
            minWidth: '10px',
          },
        };
      }
      return {
        '&.Mui-selected': {
          color: 'var(--text-primary)',
        },
        textTransform: 'capitalize',
        color: 'var(--text-secondary)',
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '36px',
      };
    },
  },
};
