export const ERROR_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};
export const RESPONSE_STATUSES = {
  CREATED: 201,
  OK: 200,
  NO_CONTENT: 204,
};
export const ROLES = {
  ADMIN: 'admin',
  EMPLOYEE: 'employee',
};
export const USER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};
export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};
export const TABLE_ALIGN = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const OFFSET = { NEXT: 1, PREV: -1 };
export const KEY_CODE = { RIGHT_ARROW: 39, LEFT_ARROW: 37 };
export const INPUT_TYPE = {
  PASSWORD: 'password',
  TEXT: 'text',
};
export const SEARCH_DELAY = 500;

export const DMA_STATUS = {
  preset_creation: 'preset_creation',
  preset_assigned: 'preset_assigned',
  dma_processing: 'dma_processing',
  dma_error: 'dma_error',
  dma_issue: 'dma_issue',
  dma_processed: 'dma_processed',
  render_ready: 'render_ready',
  rendering: 'rendering',
  render_complete: 'render_complete',
  render_error: 'render_error',
  qa: 'qa',
  qa_approve: 'qa_approve',
  qa_decline: 'qa_decline',
  brand_dispute: 'brand_dispute',
  bad_source: 'bad_source',
  finished: 'finished',
  etl_sync: 'etl_sync',
  etl_sync_error: 'etl_sync_error',
};
export const SCENE_STATUSES = {
  created: 'CREATED',
  running: 'RUNNING',
  finished: 'FINISHED',
  failed: 'FAILED',
  terminated: 'TERMINATED',
};

export const TASK_STATUSES = {
  created: 'CREATED',
  running: 'RUNNING',
  finished: 'FINISHED',
  failed: 'FAILED',
  terminated: 'TERMINATED',
};

export const DMA_STATUS_LABELS = {
  [DMA_STATUS.preset_creation]: 'Preset Creation',
  [DMA_STATUS.preset_assigned]: 'Preset Assigned',
  [DMA_STATUS.dma_processing]: 'DMA Processing',
  [DMA_STATUS.dma_error]: 'DMA Error',
  [DMA_STATUS.dma_issue]: 'DMA Issue',
  [DMA_STATUS.dma_processed]: 'DMA Processed',
  [DMA_STATUS.render_ready]: 'Render Ready',
  [DMA_STATUS.rendering]: 'Rendering',
  [DMA_STATUS.render_complete]: 'Render Complete',
  [DMA_STATUS.render_error]: 'Render Error',
  [DMA_STATUS.qa]: 'QA',
  [DMA_STATUS.qa_approve]: 'QA Approve',
  [DMA_STATUS.qa_decline]: 'QA Decline',
  [DMA_STATUS.brand_dispute]: 'Brand Dispute',
  [DMA_STATUS.bad_source]: 'Bad Source',
  [DMA_STATUS.finished]: 'Finished',
  [DMA_STATUS.etl_sync]: 'ETL Sync',
  [DMA_STATUS.etl_sync_error]: 'ETL Sync Error',
};
export const DMA_STATUS_NUMBERS = {
  [DMA_STATUS.preset_creation]: '1',
  [DMA_STATUS.preset_assigned]: '1.1',
  [DMA_STATUS.dma_processing]: '2',
  [DMA_STATUS.dma_error]: '2.1',
  [DMA_STATUS.dma_issue]: '2.2',
  [DMA_STATUS.dma_processed]: '2.3',
  [DMA_STATUS.render_ready]: '3',
  [DMA_STATUS.rendering]: '3.1',
  [DMA_STATUS.render_complete]: '3.2',
  [DMA_STATUS.render_error]: '3.3',
  [DMA_STATUS.qa]: '4',
  [DMA_STATUS.qa_approve]: '4.1',
  [DMA_STATUS.qa_decline]: '4.2',
  [DMA_STATUS.brand_dispute]: '4.3',
  [DMA_STATUS.finished]: '5',
};

export const PER_PAGE_OFFSET = {
  NEXT: 1,
  PREV: -1,
};

export const MATERIAL_STATUSES = {
  preset_creation: 'Preset Creation',
  preset_assigned: 'Preset Assigned',
  dma_processing: 'DMA Processing',
  dma_error: 'DMA Error',
  dma_processed: 'DMA Processed',
  dma_issue: 'DMA Issue',
  rendering: 'Rendering',
  render_ready: 'Render Ready',
  render_error: 'Render Error',
  render_complete: 'Render Complete',
  qa: 'QA',
  qa_approve: 'QA Approve',
  qa_decline: 'QA Decline',
  brand_dispute: 'Brand Dispute',
  bad_source: 'Bad Source',
  finished: 'Finished',
  etl_sync: 'ETL Sync',
  etl_sync_error: 'ETL Sync Error',
};

export const USER_ROLES = {
  QA: 'qa',
};

export const STAGES = {
  GENERATE_MAPS: 'generate_maps',
  ADD_SOURCE: 'add_source',
};

export const LOCAL_STORAGE_KEYS = {
  AUTH: 'auth_token',
  LAYOUT: 'layout_token',
  THUMBNAILS: 'thumbnails_token',
  SCENE_PREVIEW: 'scene_preview',
  FILTER_ORDER: 'filterOrder',
  FAVOURITE_ACTIONS: 'favourite_actions',
  ADMIN_MENU_ACTIONS: 'admin_menu_actions',
};
export const getThumbnails = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.THUMBNAILS);
  if (!value) return false;

  return value !== 'false';
};
export const getLayout = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.LAYOUT);
  if (!value) return false;

  return value !== 'false';
};
export const getScenePreview = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.SCENE_PREVIEW);

  return value !== 'false';
};
export const setLayoutToken = (value) => {
  return localStorage.setItem(LOCAL_STORAGE_KEYS.LAYOUT, value);
};
export const setThumbnailsToken = (value) => {
  return localStorage.setItem(LOCAL_STORAGE_KEYS.THUMBNAILS, value);
};
export const setScenePreview = (value) => {
  return localStorage.setItem(LOCAL_STORAGE_KEYS.SCENE_PREVIEW, value);
};
export const IS_TEST_OPTIONS = [
  { label: 'Production', value: false },
  { label: 'Test', value: true },
];
export const PRIORITY_OPTIONS = [
  { label: 'Low', value: 9 },
  { label: 'Medium', value: 6 },
  { label: 'High', value: 3 },
];
export const getIsTestLabel = (value) => {
  if (value) return 'Test';
  return 'Production';
};
export const DISABLE_NAVIGATION_CLASS = 'disable_navigation';

export const LIGHTINGS_VALUES = {
  DAY: 'day',
  NIGHT: 'night',
};
export const LIGHTING_GROUP = {
  label: 'Lightings',
  items: [LIGHTINGS_VALUES.DAY, LIGHTINGS_VALUES.NIGHT],
};
export const UPLOAD_REQUEST_TIMER = 1200000;

export const TAB_MODE = {
  TOP: 'Top',
  BOTTOM: 'Bottom',
  V2: 'V2',
  V3: 'V3',
  V3_PAGE: 'V3_PAGE',
  V3_DETAILS: 'V3_DETAILS',
};

export const SEARCH_LIMIT = 300;
export const VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  COLLAPSE: 'collapse',
};

export const NOTIFICATION_MESSAGES = {
  COPY_ERROR: 'Failed to copy text: ',
  COPIED: 'Copied',
};
