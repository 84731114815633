export const BUTTON_VARIANTS = {
  SUBMIT: 'submit',
  SAVE: 'save',
  CANCEL: 'cancel',
  FILTER: 'filter',
  TRANSPARENT: 'transparent',
  TEXT: 'text',
  TERTIARY: 'tertiary',
  WHITE: 'white',
  DARK: 'dark',
  OUTLINED_GREY: 'outlined_grey',
  ICON_BUTTON: 'icon_button',
  V3_GHOST: 'v3_ghost',
  V3_ACCENT: 'v3_accent',
  V3_ICON_TRANSPARENT: 'v3_icon_transparent',
  V3_TETRIARY: 'v3_tetriary',
  V3_OVERLAY: 'v3_overlay',
  V3_PRIMARY: 'v3_primary',
  V3_SECONDARY: 'v3_secondary',
};
export const TEXT_FIELD_VARIANTS = {
  AUTH: 'auth',
  SEARCH: 'search_class',
  PASSWORD: 'password',
  INLINE: 'inline',
  SM_SEARCH: 'small_search',
  SEARCH_40_GREY: 'search_40_grey',
  FL_SEARCH: 'filter_search',
  BASIC: 'basic',
  LARGE_WHITE: 'large_white',
  NUMBER: 'number',
  SEARCH_V3: 'search_v3',
  SEARCH_V3_WHITE: 'white_search_design_v3',
  V3_GREY: 'v3_grey',
};
export const AUTOCOMPLETE_VARIANTS = {
  GREY: 'grey',
  WHITE: 'white',
  V2_WHITE: 'v2_white',
};
export const AUTOCOMPLETE_SIZE = {
  LG: 'lg',
  MD: 'md',
  sm: 'sm',
  xmd: '40',
};
export const SELECT_VARIANTS = {
  FILTER: 'filter',
  GREY: 'grey',
  WHITE: 'white',
  TRANSPARENT: 'transparent',
  V3_GREY: 'v3_grey',
  V3_DEFAULT: 'v3_default',
};
export const TEXT_INPUT_VARIANTS = {
  STANDARD: 'standard',
  OUTLINED: 'outlined',
};

export const THEME_PREFIX = 'theme-';
