import React from 'react';

export const SlideContext = React.createContext();

export const SlideProvider = ({ children }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const swiperRef = React.useRef(null);

  const goToSlide = (index) => {
    setCurrentSlideIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  return (
    <SlideContext.Provider value={{ currentSlideIndex, goToSlide, swiperRef }}>
      {children}
    </SlideContext.Provider>
  );
};
