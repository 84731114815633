import axios from 'axios';

import { AXIOS_TIMEOUT, REQUEST_TYPE } from '@/api/constant.js';
import { _useAuth } from '@/modules/Auth/stores/auth-store.js';
import { useSnackBarStore } from '@/modules/SnackBar/store/index.js';
import { SNACKBAR_VARIANT } from '@/modules/SnackBar/store/index.js';
import { getToken, TOKEN_TYPE } from '@/utils/auth.js';
import { getTextAfterApi } from '@/utils/common.js';
import { ERROR_CODES, RESPONSE_STATUSES } from '@/utils/constant.js';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_API_URL,
  timeout: AXIOS_TIMEOUT,
});

const PERMISSIONS_ERROR_MESSAGE = 'Not enough permissions';
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.authorization !== false) {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `${TOKEN_TYPE.BEARER} ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === RESPONSE_STATUSES.CREATED) {
      useSnackBarStore.getState().setOpen('Successfully created');
    }

    return response;
  },
  async (error) => {
    // handle expired token
    if (error?.response?.status === ERROR_CODES.UNAUTHORIZED) {
      if (
        error?.response?.data?.detail ===
        'All user manufacturers is deactivated'
      ) {
        console.error(error?.response?.data?.detail);
      } else {
        _useAuth.getState().signOut();
        document.location.href = '/';
      }
    }
    if (error?.response?.status === ERROR_CODES.FORBIDDEN) {
      if (error?.response?.data?.detail === PERMISSIONS_ERROR_MESSAGE) {
        useSnackBarStore.getState().setOpen(
          `Insufficient permissions:
           ${REQUEST_TYPE[error?.config?.method]}: ${getTextAfterApi(error?.request?.responseURL)}`,
          SNACKBAR_VARIANT.ERROR,
        );
        return;
      } else {
        _useAuth.getState().setChangePasswordStatus();
      }
    }

    return Promise.reject(error);
  },
);

class HTTP {
  constructor(instance) {
    this.instance = instance;
  }

  get(url, params) {
    return this.instance.get(url, params);
  }
  post(url, body, config) {
    return this.instance.post(url, body, config);
  }
  put(url, body, config) {
    return this.instance.put(url, body, config);
  }
  patch(url, body, config) {
    return this.instance.patch(url, body, config);
  }
  delete(url, config) {
    return this.instance.delete(url, config);
  }
}

const httpService = new HTTP(axiosInstance);

export default httpService;
