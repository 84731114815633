import { TAB_MODE } from '@/utils/constant.js';

export const MuiTabs = {
  styleOverrides: {
    indicator: ({ ownerState: { mode = TAB_MODE.BOTTOM } }) => {
      if (mode === TAB_MODE.V2) {
        return {
          background: 'var(--color-white)',
        };
      }
      if ([TAB_MODE.V3, TAB_MODE.V3_PAGE].includes(mode)) {
        return {
          background: 'none',
        };
      }

      if ([TAB_MODE.V3_DETAILS].includes(mode)) {
        return {
          background: 'var(--color-black)',
          top: 0,
          height: '1px',
        };
      }

      return {
        background: 'var(--text-primary)',
        ...(mode === TAB_MODE.TOP && { top: 0 }),
        ...(mode === TAB_MODE.BOTTOM && { bottom: 0 }),
      };
    },
    scroller: ({ ownerState: { withBorder, mode } }) => {
      if (mode === TAB_MODE.V2) {
        return {
          borderBottom: 'none',
          display: 'flex',
          justifyContent: 'center',
        };
      }
      if (mode === TAB_MODE.V3) {
        return {
          borderBottom: 'none',
          display: 'flex',
          justifyContent: 'center',
          gap: '1.7rem',
        };
      }
      return {
        borderBottom: withBorder ? '1px solid var(--bg-light-grey-2)' : 'none',
      };
    },
    flexContainer: ({ ownerState: { mode = TAB_MODE.BOTTOM } }) => {
      if (mode === TAB_MODE.V3) {
        return {
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1.7rem',
        };
      }
      if (mode === TAB_MODE.V3_DETAILS) {
        return {
          gap: '1.6rem',
          borderTop: 'thin solid var(--light-grey-1-color);',
        };
      }
      return {
        alignItems: 'center',
      };
    },
  },
};
