import Brands from '@/assets/svg/design-v3/Brands.svg?react';
import Families from '@/assets/svg/design-v3/Families.svg?react';
import InstallShot from '@/assets/svg/design-v3/InstallShot.svg?react';
import LogView from '@/assets/svg/design-v3/LogView.svg?react';
import PresetClasses from '@/assets/svg/design-v3/PresetClasses.svg?react';
import Presets from '@/assets/svg/design-v3/Presets.svg?react';
import RenderType from '@/assets/svg/design-v3/RenderType.svg?react';
import Scene from '@/assets/svg/design-v3/Scene.svg?react';
import ScenePreview from '@/assets/svg/design-v3/ScenePreview.svg?react';
import Surface from '@/assets/svg/design-v3/Surface.svg?react';
import Users from '@/assets/svg/design-v3/Users.svg?react';
import { PERMISSION_KEYS_ENUM } from '@/utils/permissions/index.js';
import { ROUTES } from '@/utils/routes.js';

export const ROUTE_OPTIONS = [
  {
    label: 'Log view',
    icon: () => <LogView />,
    route: ROUTES.logView,
    permissionKeys: [PERMISSION_KEYS_ENUM.MATERIAL_TASKS],
  },
  {
    label: 'Brands',
    icon: () => <Brands />,
    route: ROUTES.brands,
    permissionKeys: [PERMISSION_KEYS_ENUM.MANUFACTURERS],
  },
  {
    label: 'Collections',
    icon: () => <Families />,
    route: ROUTES.collections,
    permissionKeys: [PERMISSION_KEYS_ENUM.COLLECTIONS],
  },
  {
    label: 'Presets',
    icon: () => <Presets />,
    route: ROUTES.presets,
    permissionKeys: [PERMISSION_KEYS_ENUM.PRESETS],
  },
  {
    label: 'Preset classes',
    icon: () => <PresetClasses />,
    route: ROUTES.presetClasses,
    permissionKeys: [PERMISSION_KEYS_ENUM.PRESET_CLASSES],
  },
  {
    label: 'Scenes',
    icon: () => <Scene />,
    route: ROUTES.scenes,
    permissionKeys: [PERMISSION_KEYS_ENUM.SCENES],
  },
  {
    label: 'Scenes preview',
    icon: () => <ScenePreview />,
    route: ROUTES.scenesPreview,
    permissionKeys: [PERMISSION_KEYS_ENUM.SCENES],
  },
  {
    label: 'Surfaces',
    icon: () => <Surface />,
    route: ROUTES.surfaces,
    permissionKeys: [PERMISSION_KEYS_ENUM.SURFACES],
  },
  {
    label: 'Render type',
    icon: () => <RenderType />,
    route: ROUTES.renderTypes,
    permissionKeys: [PERMISSION_KEYS_ENUM.RENDER_TYPES],
  },
  {
    label: 'Install shots',
    icon: () => <InstallShot />,
    route: ROUTES.installShots,
    permissionKeys: [PERMISSION_KEYS_ENUM.INSTALL_SHOTS],
  },
  {
    label: 'Roles',
    icon: () => <RenderType />,
    route: ROUTES.roles,
    permissionKeys: [PERMISSION_KEYS_ENUM.ROLES],
  },
  {
    label: 'Users',
    icon: () => <Users />,
    route: ROUTES.users,
    permissionKeys: [PERMISSION_KEYS_ENUM.USERS],
  },
];
