export const sizeStyles = {
  sizeXxsmall: {
    lineHeight: '2.4rem',
    fontSize: 'var(--font-size-sm-1)',
    fontWeight: 400,
    padding: '.4rem .8rem',
  },
  sizeXsmall: {
    lineHeight: '2.4rem',
    fontSize: 'var(--font-size-lg)',
    fontWeight: 400,
    padding: '.6rem 1.6rem',
  },
  sizeSmall: {
    lineHeight: '2.4rem',
    fontSize: 'var(--font-size-lg)',
    fontWeight: 400,
    padding: '1.2rem 2.4rem',
  },
  sizeXm: {
    lineHeight: '1.6rem',
    fontSize: 'var(--font-size-sm-1) !important',
    fontWeight: 400,
    padding: '.8rem 1.2rem',
    borderRadius: '8px !important',
  },
  sizeMedium: {
    height: '4.8rem',
    borderRadius: '24px',
    lineHeight: '2.4rem',
    padding: '12px, 16px, 12px, 16px',
    fontSize: 'var(--font-size-lg)',
  },
  sizeLarge: {
    width: '100%',
    borderRadius: '32px',
    fontWeight: 400,
    fontSize: 'var(--font-size-lg)',
    height: '6.4rem',
  },
};
